import { createGlobalStyle } from 'styled-components';

const theme = sessionStorage.getItem('jsp-primary');
const themeOpaque = (theme == null ? '#6777ef' : theme) + 'c0';
const themeHover = (theme == null ? '#6777ef' : theme) + 'c9';

//console.log('theme: ' + theme);
//console.log(data.school.favicon)

export const GlobalStyles = createGlobalStyle`

:root {
    --jsp-primary: ${theme};
    --jsp-primary-hover: ${themeHover};
    --jsp-primary-opaque: ${themeOpaque};
} 



body {
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.color.text};
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, 'Georgia', 'Times New Roman', 'Monaco', 'Courier New', 'Monospace';
    transition: all 0.25s linear;
}

.page-item.active .page-link {
    background-color: ${theme} !important;
    border: 1px solid black;
    color: white !important;
}
.page-link {
    color: black !important;
}

.tab1
{
    background-color:yellow !important;
}
.p-tabview-selected{
    'background-color': ${theme} !important;
  }


/* Style the active tab */
.my-tabview .p-tabview-nav .p-tabview-nav-item.p-highlight {
  background-color: #007bff !important;
  color: ${theme} !important;
}

/* Style the inactive tabs */
.my-tabview .p-tabview-nav .p-tabview-nav-item {
  background-color: #ddd;
  color: ${theme} !important;
}

select:valid {
    color: ${({ theme }) => theme.color.selectValid};
  }

option[value=""] {
    color: #c4c4c4 !important;
}

.toggler {
    position: relative;
    left: 92%;
    top: 30px;
}

.login-card {
    background: ${({ theme }) => theme.color.card};
    border-top: var(--jsp-primary) 3px solid;
}

.card-header {
  border-bottom-color: ${({ theme }) => theme.color.toggleBorder};
}

.card h4 {
    color: ${({ theme }) => theme.color.primary};
}

.btn {
    display: flex;
    gap: 10px;
    padding: 10px;
}

.btn.btn-primary:hover {
    background-color: var(--jsp-primary-hover);
    color: ${({ theme }) => theme.color.buttonText} !important;
}

.toggler-button i[class="fa-moon fa"] {
    color: var(--jsp-primary) !important;
}

.university-layout .toggler-button i[class="fa-moon fa"],
.toggler-button, i[class="fa-moon fa"]:hover {
    color: ${({ theme }) => theme.color.buttonThemedHover} !important;
}

.form-group-button button {
    background-color: ${({ theme }) => theme.color.buttonBg};
    color: ${({ theme }) => theme.color.buttonText};
    box-shadow: 0 2px 6px ${({ theme }) => theme.color.buttonShadow};
}

.form-group-button button:hover {
    background-color: var(--jsp-primary-hover); 
    color: ${({ theme }) => theme.color.buttonText};
    box-shadow: 0 2px 6px ${({ theme }) => theme.color.buttonShadowHover};

}


.form-control {
    background: ${({ theme }) => theme.color.formControlBg};
    color: ${({ theme }) => theme.color.text};
}

.form-control-alt {
    background-color: ${({ theme }) => theme.color.formControlBg} !important;
    color: ${({ theme }) => theme.color.text};
}

.form-control.form-control-alt:focus {
    color: ${({ theme }) => theme.color.text};
}

.form-control option {
    border: 0;
}

.bg-white {
    background: ${({ theme }) => theme.color.bgWhite} !important;
}

.bg-primary {
    background: var(--jsp-primary) !important;
}

.border-primary {
    border-color: var(--jsp-primary) !important;
}

.border-orange-100 {
    border-color: ${({ theme }) => theme.color.orange100} !important;
}

.text-primary {
    color: var(--jsp-primary) !important;
}

.nav-tab {
    border: 0;
    border-bottom: 3px solid transparent;
}

.nav-tab.active {
    border-bottom-color: var(--jsp-primary);
}
    
.text-gray-label {
  color: ${({ theme }) => theme.color.grayLabel};
}
.text-gray-600 {
  color: ${({ theme }) => theme.color.textGray600};
}

.text-gray-900 {
  color: ${({ theme }) => theme.color.textGray900};
}

.text-header {
  color: ${({ theme }) => theme.color.textHeader};
}

.text-gray-Bodytext1 {
  color: ${({ theme }) => theme.color.textGrayBodytext1};
}

.text-gray-Bodytext2 {
  color: ${({ theme }) => theme.color.textGrayBodytext2};
}

.text-gray-dark {
  color: ${({ theme }) => theme.color.grayDark} !important;
}

.bg-dark-pink {
  background-color: ${({ theme }) => theme.color.darkPinkBorder};
}

.bg-gray-200 {
  background-color: ${({ theme }) => theme.color.bgGray200} !important;
}

.bg-gray-bg2 {
  background: ${({ theme }) => theme.color.bgGrayBg2};
}
.bg-noti {
  background: ${({ theme }) => theme.color.notiBg};
}
.bg-note {
  background: ${({ theme }) => theme.color.noteBg};
}
.bg-light-pink {
  background: ${({ theme }) => theme.color.lightPink};
}

.btn-primary {
    background: var(--jsp-primary) !important;
}

.pending-badge {
  color: #b54708;
  background-color: #fffaeb;
  border-color: #fedf89 !important;
}

.success-badge {
  color: #067647;
  background-color: #ecfdf3;
  border-color: #abefc6 !important;
}

.error-badge {
  color: #b42318;
  background-color: #fef3f2;
  border-color: #fecdca !important;
}

.text-black {
    color:  ${({ theme }) => theme.color.text} !important;
}

.nav-main-link:hover, .nav-main-link.active {
    color: var(--jsp-primary) !important;
    background: ${({ theme }) => theme.color.navMainLinkBg} !important;
}

.nav-main-link-name:hover {
    color: var(--jsp-primary) !important;
}

.nav-main-link.active {
    border-left: 5px solid var(--jsp-primary) !important;;
}

.university-layout .nav-main-link.active {
    border-left: none !important;;
}

.text-muted {
    color:  ${({ theme }) => theme.color.textMuted} !important;
}

.block-header {
    background: ${({ theme }) => theme.color.bgWhite} !important;
}

.loading-overlay {
    z-index: 10000;
    background: rgba(52, 64, 84, .6);
}

.content-bg {
    background: ${({ theme }) => theme.color.contentBg} !important;
}

.carousel-head {
    background: ${({ theme }) => theme.color.dataTableHead} !important;
}

.subject {
    color:  ${({ theme }) => theme.color.subjectTableText} !important;
    box-shadow: inset 0 0 0 9999px  ${({ theme }) =>
      theme.color.subjectTableBg} !important;
}

.subject-dark {
    color:  ${({ theme }) => theme.color.subjectTableText} !important;
    --bs-table-accent-bg: transparent !important;
}

.secured-panel-body-gray {
    background: ${({ theme }) => theme.body};
}

.grade {
    color:  ${({ theme }) => theme.color.subjectTableText} !important;
    box-shadow: inset 0 0 0 9999px  ${({ theme }) =>
      theme.color.subjectTableBg} !important;
}

.grade-dark {
    color:  ${({ theme }) => theme.color.subjectTableText} !important;
}

a.text-black:hover, a.text-black:focus {
    color:  ${({ theme }) => theme.color.text} !important;
}

.footer-contain {
    background: ${({ theme }) => theme.body};
}

.toggler-button {
    background: ${({ theme }) => theme.color.bgWhite};
}

.university-layout .toggler-button {
    background: none;
    border: 0 !important;
}

.dropdown-item {
    color: #b4bccb !important;
  }

.dropdown-item:hover, .dropdown-item:focus {
    color:  var(--jsp-primary) !important;
    background: ${({ theme }) => theme.color.dropdownHoverBg} !important;
}

.notification-dropdown-list {
    color:  ${({ theme }) => theme.color.text} !important;
    border-bottom: 1px solid ${({ theme }) =>
      theme.color.toggleBorder} !important;
}

.notification-dropdown-list:hover {
    background: ${({ theme }) => theme.color.dropdownHoverBg} !important;
    color:  ${({ theme }) => theme.color.text} !important;
}

.notification-text {
    color:  ${({ theme }) => theme.color.text} !important;
}

.notification-dropdown-list:focus, .notification-dropdown-list:active {
    background: ${({ theme }) => theme.color.dropdownHoverBg} !important;
    color:  ${({ theme }) => theme.color.text} !important;
    border-left: 3px solid var(--jsp-primary) !important;;
}

a.bg-primary:hover, a.bg-primary:focus, button.bg-primary:hover, button.bg-primary:focus {
    background: var(--jsp-primary-hover) !important;
}

.js-sidebar-scroll {
    background: ${({ theme }) => theme.color.bgWhite};
}

.sidebar-close-btn {
    color:  ${({ theme }) => theme.color.text} !important;
} 

.profile-btn-text-selected {
    color:  ${({ theme }) => theme.color.profileButtonTextSelected} !important;
}

.profile-btn-text-white {
    color:  ${({ theme }) => theme.color.profileButtonText};
}

.activities-header-sp {
    color:  ${({ theme }) => theme.color.subjectTableText} !important;
    box-shadow: inset 0 0 0 9999px  ${({ theme }) => theme.color.subjectTableBg} !important;
  }

.table-head {
    background: ${({ theme }) => theme.color.dataTableHead};
}
.MuiPaper-root.MuiAccordion-root::before {
    opacity: 0 !important;
}
.MuiFormControlLabel-label {
    font: inherit;
}
.MuiPaper-root .MuiMenu-list,
.MuiDrawer-paperAnchorRight,
.MuiInputLabel-formControl {
    background-color: ${({ theme }) => theme.color.bgWhite} !important;
}
.MuiPaper-root .MuiMenu-list .MuiMenuItem-root {
    color: ${({ theme }) => theme.color.grayDark} !important;
    font-family: inherit !important;
}
.university-layout .MuiFormControl-root,
.university-layout .MuiFormControl-root label,
.university-layout .MuiFormControl-root textarea,
.university-layout .MuiFormControl-root input {
    color: ${({ theme }) => theme.color.textGrayBodytext2} !important;
}
.university-layout .MuiFormControl-root textarea,
.university-layout .MuiFormControl-root input {
    border-color: ${({ theme }) => theme.color.textGrayBodytext2} !important;
}
.university-layout .MuiFormControl-root input:disabled {
    opacity: 0.5;
    -webkit-text-fill-color: ${({ theme }) => theme.color.textGrayBodytext2};
}

.css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
    color: ${({ theme }) => theme.color.textGrayBodytext2} !important;
}
.university-layout .MuiFormControl-root input {
    font-size: 0.875rem !important;
}
.university-layout .MuiFormControl-root .Mui-focused,
.university-layout .MuiFormControl-root label.Mui-focused,
.university-layout .MuiFormControl-root .Mui-focused input {
    color: ${({ theme }) => theme.color.primary} !important;
}

.university-template-editor .cke_contents {
    height: 90vh !important;
}

.guide-card {
    background: ${({ theme }) => theme.color.orange100};
    border-left: 3px solid ${({ theme }) => theme.color.darkPinkBorder};
}

.grid-card {
    background: ${({ theme }) => theme.color.notiBg};
    border: 1px solid ${({ theme }) => theme.color.bgGray200};
}

.new-noti {
    background: ${({ theme }) => theme.color.notiBg};
    border-left: 3px solid ${({ theme }) => theme.color.notiBorder};
}

.table-uni > tbody > tr:hover {
    background: rgba(75, 85, 99, 0.2);
}
`;
